<template>
    <div class="PageBox finListBox">
        <van-nav-bar
                fixed
                :border="false"
                :title="$t('my2[2]')"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div>
        <div style="margin-top: 45px;"></div>

        <div style="margin-left: 10px;margin-right: 10px" :key="index" v-for="(item,index) in listData">
            <div class="tool">
                <div >{{$t('fin[0]')}}: {{parseInt(item.amount)}} {{InitData.usdtinfo.sign}}</div>
                <div >{{$t('fin[1]')}}: {{item.configId}} {{$t('my2[0]')}}</div>
                <div >{{$t('fin[2]')}}: {{parseFloat(item.fee).toFixed(2)}}%</div>
                <div >{{$t('fin[3]')}}: {{(new Date((item.open_time+item.configId*60*60*24)*1000)).Format("yyyy-MM-dd")}}</div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import config from '@/config'

    Date.prototype.Format =  function (fmt) {  // author: meizz
        var o = {
            "M+":  this.getMonth() + 1,  // 月份
            "d+":  this.getDate(),  // 日
            "h+":  this.getHours(),  // 小时
            "m+":  this.getMinutes(),  // 分
            "s+":  this.getSeconds(),  // 秒
            "q+": Math.floor(( this.getMonth() + 3) / 3),  // 季度
            "S":  this.getMilliseconds()  // 毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, ( this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for ( var k  in o)
            if ( new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    const AppDown = config.AppDown
    export default {
        name: 'finlist',
        components: {},
        props: [],
        data() {
            return {
                token:'',
                statisticalData: {},
                uid:'',//用户ID
                configData: {},
                listData:[],
                form:{
                    id:0,
                },
            }
        },
        computed: {

        },
        created() {
            this.token = localStorage.getItem('Token');
            this.GetFinList()
        },
        methods: {
            GetFinList() {
                this.$Model.GetFinData({token:this.token},data => {
                    this.listData = data.info
                })

            },
            outFin(index,id){
                console.log('outFinoutFinoutFin')
                this.$Dialog.Confirm(this.$t('fin[18]'), () => {
                    this.$Model.outFin(this.form,data=>{
                        if(data.code==1){
                            this.listData[index].status = 2
                            this.$Dialog.Confirm(this.$t('fin[20]'), () => {
                                this.$router.push("/user");
                            },this.$t('fin[21]'))
                        }
                    })
                },this.$t('fin[19]'))
                this.form['token'] = this.token;
                this.form['id'] = id
            }
        }
    }
</script>
<style scoped>
    .PageBox {
        color: #d7d7d7;
        background-color: #13171A;
    }

    .PageBox >>>.van-nav-bar {
        background-color: #191C23;
    }

    .van-nav-bar>>>.van-nav-bar__title{
        color: #fff;
    }
    .van-nav-bar>>>.van-nav-bar__arrow {
        color:#fff;
    }
    .finListBox {
        color: #000000;
        padding: 0;
        background-color: #13171A;
        min-height: 100px;
        height: 1800px;
    }

    .finListBox .tool {
        width: 100%;
        border-radius: 12px;
        background-color: #191C23;
        color: #d7d7d7;
        overflow: hidden;
        margin: 5px auto;
        margin-top: 10px;
        line-height: 20px;
        z-index: 99;
        padding:10px;
    }
    .button3{
        background-color: #7c7cd8;
        color: #ffffff;
        width: 70px;height: 30px
    }

    .button4{
        background-color: #d9d8fb;
        color: #000000;
        margin-left: -5px;
        width: 80px;height: 30px
    }




</style>
